import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
const Home = () => import('@/views/Home')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
    // redirect: '/Categories'
  },
  {
    path: '/categories/:cat_id/:cat_name',
    name: 'Categories',
    component: () => import(/* webpackChunkName: "categories" */ '../views/Page/Categories.vue'),
    props: true
  },
  {
    path: '/contents/:content_id/:cat_name',
    name: 'Contents',
    component: () => import(/* webpackChunkName: "contents" */ '../views/Page/Contents.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/categorie',
    name: 'Categorie',
    component: () => import(/* webpackChunkName: "categorie" */ '../views/Page/Categorie.vue')
  },
  {
    path: '/new',
    name: 'New',
    component: () => import(/* webpackChunkName: "new" */ '../views/Page/New.vue')
  },
  {
    path: '/poets',
    name: 'Poets',
    component: () => import(/* webpackChunkName: "poets" */ '../views/Page/Poets.vue')
  },
  {
    path: '/searchs/:search_id',
    name: 'Searchs',
    component: () => import(/* webpackChunkName: "searchs" */ '../views/Page/Searchs.vue'),
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/User/Login.vue'),
  },
  {
    path: '/unsubscribes',
    name: 'Unsubscribes',
    component: () => import(/* webpackChunkName: "unsubscribes" */ '../views/User/Unsubscribes.vue'),
    meta: {
      IsAuth: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

router.beforeEach((to, from, next ) => {
  const { $cookie } = router.app.config.globalProperties
   if(to.meta.IsAuth) {
    if($cookie.isCookieAvailable("msisdn") && $cookie.isCookieAvailable("status")) {
      next();
    } else {
      next(window.open("http://sudanipay.com/subscription/nagham/Nagham", "_self"));
    }
   } else {
     next();
   }
})

export default router
